function checkEmail(term) {
  if (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@/.test(term)) {
    return true;
  }
  return false;
}

function cleanObject(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function replaceAll(search, replace, string) {
  return string.split(search).join(replace);
}

function wlExecute(client, modal, version) {
  try {
    const clientWithOpenModal = {
      client,
      openModal: modal || false,
    };
    console.log("version juste avant jsonStringClientWithModal", version);
    const jsonStringClientWithModal = JSON.stringify(
      version ? clientWithOpenModal : client
    );
    console.log(jsonStringClientWithModal);
    return WL.Execute("ValideClient", jsonStringClientWithModal);
  } catch (error) {
    console.error(error);
  }
}

function testChecksum(ean) {
  const digits = ean.slice(0, -1);
  const checkDigit = ean.slice(-1) | 0;
  let sum = 0;
  for (let i = digits.length - 1; i >= 0; i--) {
    sum += (digits.charAt(i) * (1 + 2 * (i % 2))) | 0;
  }
  sum = (10 - (sum % 10)) % 10;
  return sum === checkDigit;
}

function formatStringWithoutDiacritics(valeur) {
  const valeurSansApostrophe = valeur.replace(/'/g, " ");
  const valeurSansDiacritics = valeurSansApostrophe.replace(
    /[`~!@#$^&()_|+\=?;:'',.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const valeurFormate = valeurSansDiacritics
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return valeurFormate.trim().toUpperCase();
}

function checkEan(eanCode) {
  // Check if only digits

  var ValidChars = "0123456789";

  for (i = 0; i < eanCode.length; i++) {
    digit = eanCode.charAt(i);

    if (ValidChars.indexOf(digit) == -1) {
      return false;
    }
  }

  // Add five 0 if the code has only 8 digits
  if (eanCode.length !== 13) {
    return false;
  }

  // Get the check number

  originalCheck = eanCode.substring(eanCode.length - 1);

  eanCode = eanCode.substring(0, eanCode.length - 1);

  // Add even numbers together

  even =
    Number(eanCode.charAt(1)) +
    Number(eanCode.charAt(3)) +
    Number(eanCode.charAt(5)) +
    Number(eanCode.charAt(7)) +
    Number(eanCode.charAt(9)) +
    Number(eanCode.charAt(11));

  // Multiply this result by 3

  even *= 3;

  // Add odd numbers together

  odd =
    Number(eanCode.charAt(0)) +
    Number(eanCode.charAt(2)) +
    Number(eanCode.charAt(4)) +
    Number(eanCode.charAt(6)) +
    Number(eanCode.charAt(8)) +
    Number(eanCode.charAt(10));

  // Add two totals together

  total = even + odd;

  // Calculate the checksum

  // Divide total by 10 and store the remainder

  checksum = total % 10;

  // If result is not 0 then take away 10

  if (checksum != 0) {
    checksum = 10 - checksum;
  }

  // Return the result

  if (checksum != originalCheck) {
    return false;
  }
  return true;
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}
